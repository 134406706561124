import React, { createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';
import AutoSizer from 'react-virtualized-auto-sizer';
import { motionConfigContext, SmartMotion, ThemeProvider } from '@nivo/core';
import { Line } from '@nivo/line';
import { scaleLinear } from 'd3-scale';
import { motion } from 'framer-motion';
import ContentEditable from 'react-contenteditable';
import cn from 'classnames';
import delay from 'lodash/delay';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';
import throttle from 'lodash/throttle';
import { windowWidth } from 'modules/Layout/selectors';
import intlShape from 'shapes/intlShape';
import { formatTimestamp } from 'helpers/datetime';
import { isManualReading } from 'helpers/externalDataSources';
import { isAggregatedPostMeal } from 'libs/StatsCalculations';
import Button from 'components/Form/Button';
import ReadingFlagIcon from 'components/ReadingFlagIcon';
import Pencil from 'svg/pencil.svg';
import DirectionHorizontalArrow from 'svg/directionHorizontalArrow.svg';
import Split from 'svg/split.svg';
import ThrashBin from 'svg/trash-bin.svg';
import * as selectors from 'modules/PatientResults/selectors';
import messages from '../messages';
import BloodGlucoseConcentrationChartRelatedData from './BloodGlucoseConcentrationChartRelatedData';
import styles from './BloodGlucoseConcentrationChart.pcss';
import { HOUR_RANGE } from './constants';


class BloodGlucoseConcentrationChart extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const {
      /* eslint-disable react/prop-types */
      conversion, standards, readings, start, end, ticks, maxPlainValue,
      isPlainValuesMode, isCustomRanges, isInProgress,
      timestampKey, valueKey, averageData, activeMeasurement,
      /* eslint-enable react/prop-types */
    } = props;
    const activePointTimestamp = activeMeasurement ? activeMeasurement.timestamp : 0;

    let { selectedPoint } = state;
    if (selectedPoint !== activePointTimestamp) {
      selectedPoint = activePointTimestamp;
    }

    if (readings === state.readings
      && isInProgress === state.isInProgress
      && !state.isInProgress
      && props.windowWidth === state.windowWidth) {
      return { selectedPoint };
    }

    const all = [];
    const high = [];
    const target = [];
    const low = [];
    const timestamps = ticks || [];
    const averageAll = [];
    const averageHigh = [];
    const averageTarget = [];
    const averageLow = [];
    const highReadings = [];
    const targetReadings = [];
    const lowReadings = [];

    const lowPreMealThreshold = conversion.toDisplay(standards.preMeal.lowThreshold);
    const highPreMealThreshold = conversion.toDisplay(standards.preMeal.highThreshold);
    const lowPostMealThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
    const highPostMealThreshold = conversion.toDisplay(standards.postMeal.highThreshold);

    let firstTick;
    let lastTick;
    const tickParts = [];

    if (!ticks) {
      const daySeconds = 86400;
      firstTick = +moment.unix(start).utc().locale('en--account')
        .startOf('day')
        .format('X');
      lastTick = +moment.unix(end).utc().locale('en--account')
        .startOf('day')
        .add(1, 'days')
        .format('X');
      const difference = (lastTick - firstTick) / daySeconds;
      const maxNofElements = props.windowWidth > 1680 ? 14 : 10;
      const multiplier = Math.max(Math.ceil(difference / maxNofElements), 1);
      for (let i = firstTick; i <= lastTick; i += daySeconds * multiplier) {
        if (timestamps.length >= maxNofElements) break;
        timestamps.push(i);
      }
    } else {
      firstTick = timestamps[0];
      lastTick = timestamps[timestamps.length - 1];
      const totalDiff = lastTick - firstTick;
      // eslint-disable-next-line react/prop-types
      for (let index = 1; index < ticks.length; index++) {
        const elementStart = ticks[index - 1];
        const elementEnd = ticks[index];

        const elementDiff = elementEnd - elementStart;
        tickParts.push({
          start: elementStart,
          end  : elementEnd,
          width: elementDiff / totalDiff,
        });
      }
    }

    const stdDevs = {};
    const originalTimestamps = {};
    const flags = {};
    const manualReadings = [];
    let maxReadingValue = 0;
    let oldestReadingTimestamp = 0;
    let newestReadingTimestamp = 0;

    forEach(readings, (reading) => {
      const x = reading[timestampKey];
      if (!oldestReadingTimestamp || oldestReadingTimestamp > x) {
        oldestReadingTimestamp = x;
      }
      if (!newestReadingTimestamp || newestReadingTimestamp < x) {
        newestReadingTimestamp = x;
      }
      const y = isPlainValuesMode ? reading[valueKey] : conversion.toDisplay(reading[valueKey]);
      if (y > maxReadingValue) {
        maxReadingValue = y;
      }
      const highConverted = isAggregatedPostMeal(reading.flags) ? highPostMealThreshold : highPreMealThreshold;
      const lowConverted = isAggregatedPostMeal(reading.flags) ? lowPostMealThreshold : lowPreMealThreshold;

      all.push({ x, y });
      if (!isPlainValuesMode) {
        if (y > highConverted) {
          high.push({ x, y });
          highReadings.push(x);
        } else if (y < lowConverted) {
          low.push({ x, y });
          lowReadings.push(x);
        } else {
          target.push({ x, y });
          targetReadings.push(x);
        }
      }

      if (!isUndefined(reading.stdDev)) {
        stdDevs[x] = isPlainValuesMode ? reading.stdDev : conversion.toDisplay(reading.stdDev);
      }
      if (reading.originalTimestamp) {
        originalTimestamps[x] = reading.originalTimestamp;
      }
      flags[x] = reading.flags;
      if (isManualReading(reading)) {
        manualReadings.push(x);
      }
    });
    forEach(averageData, (averageReading) => {
      const x = averageReading[timestampKey];
      const y = isPlainValuesMode ? averageReading[valueKey] : conversion.toDisplay(averageReading[valueKey]);
      if (y > maxReadingValue) {
        maxReadingValue = y;
      }

      averageAll.push({ x, y });
      const highConverted = Math.max(highPostMealThreshold, highPreMealThreshold);
      const lowConverted = Math.min(lowPostMealThreshold, lowPreMealThreshold);

      if (!isPlainValuesMode) {
        if (y > highConverted) {
          averageHigh.push({ x, y });
        } else if (y < lowConverted) {
          averageLow.push({ x, y });
        } else {
          averageTarget.push({ x, y });
        }
      }
    });

    if (isInProgress) {
      const preLoaderTimestamps = [];
      const postLoaderTimestamps = [];
      for (let i = 1; i <= timestamps.length; i += 2) {
        const placeholderValue = 100 + (100 / (i * 2));
        if (timestamps[i] < oldestReadingTimestamp) {
          preLoaderTimestamps.push({ x: timestamps[i], y: conversion.toDisplay(placeholderValue) });
        } else if (timestamps[i] > newestReadingTimestamp) {
          postLoaderTimestamps.push({ x: timestamps[i], y: conversion.toDisplay(placeholderValue) });
        }
      }
      if (preLoaderTimestamps.length) {
        all.unshift(...preLoaderTimestamps);
      }
      if (postLoaderTimestamps.length) {
        all.push(...postLoaderTimestamps);
      }
    }

    let step = 5;
    if (isPlainValuesMode) {
      step = 10;
    } else if (conversion.unit === 'MG_DL') {
      step = 50;
    }
    const maxStandardValue = isPlainValuesMode ? maxPlainValue : Math.ceil(conversion.toDisplay(standards.maxValue));
    const maxValue = Math.max(maxStandardValue, Math.ceil(maxReadingValue / step) * step);
    const minValue = isPlainValuesMode ? 0 : conversion.toDisplay(standards.minValue, 1);
    const valuesScale = [minValue];
    let valueTick = step;
    while (valueTick <= maxValue) {
      valuesScale.push(valueTick);
      valueTick += step;
    }

    const customRangesLabelsRefs = {};
    if (isCustomRanges) {
      for (let i = 0; i < timestamps.length - 1; i++) {
        customRangesLabelsRefs[i] = createRef(null);
      }
    }

    return {
      data: [
        { id: 'all', data: all },
        { id: 'high', data: high },
        { id: 'target', data: target },
        { id: 'low', data: low },
        { id: 'averageAll', data: averageAll },
        { id: 'averageHigh', data: averageHigh },
        { id: 'averageTarget', data: averageTarget },
        { id: 'averageLow', data: averageLow },
      ],
      stdDevs,
      originalTimestamps,
      readings,
      timestamps,
      dragTimestamps: isCustomRanges ? timestamps : [],
      customRangesLabelsRefs,
      flags,
      manualReadings,
      firstTick,
      lastTick,
      maxValue,
      minValue,
      valuesScale,
      isInProgress,
      tickParts,
      highReadings,
      targetReadings,
      lowReadings,
      selectedPoint,
    };
  }

  static propTypes = {
    // Implicit props
    windowWidth: PropTypes.number,
    // Explicit props
    conversion : PropTypes.object.isRequired,
    direction  : PropTypes.string.isRequired,
    standards  : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      minValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    tooltipConfig: PropTypes.shape({
      dateFormat: PropTypes.string,
      timeFormat: PropTypes.string,
    }),
    customRangesLabels      : PropTypes.arrayOf(PropTypes.string),
    formatLabel             : PropTypes.func,
    unitSymbol              : PropTypes.string,
    isLineEnabled           : PropTypes.bool,
    isGridXEnabled          : PropTypes.bool,
    isPlainValuesMode       : PropTypes.bool,
    plainValuesColor        : PropTypes.string,
    isCustomRanges          : PropTypes.bool,
    isReadOnly              : PropTypes.bool,
    disableTooltip          : PropTypes.bool,
    enableGridY             : PropTypes.bool,
    gridTheme               : PropTypes.object,
    criticalLow             : PropTypes.number,
    criticalHigh            : PropTypes.number,
    intl                    : intlShape,
    highlightedReadings     : PropTypes.array,
    start                   : PropTypes.number,
    end                     : PropTypes.number,
    relatedData             : PropTypes.array,
    timeSeriesResources     : PropTypes.array,
    measurements            : PropTypes.array,
    showRelatedData         : PropTypes.bool,
    activePointTimestamp    : PropTypes.number,
    activeMeasurement       : PropTypes.object,
    // Explicit actions
    onAddNote               : PropTypes.func,
    onMouseMove             : PropTypes.func,
    onMouseLeave            : PropTypes.func,
    onUpdateCustomRange     : PropTypes.func,
    onUpdateCustomRangeLabel: PropTypes.func,
    onSplitCustomRange      : PropTypes.func,
    onRemoveCustomRange     : PropTypes.func,
    onPointClick            : PropTypes.func,
  };


  static defaultProps = {
    tooltipConfig: {},
    unitSymbol   : null,
    onMouseMove  : () => {},
    enableGridY  : false,
    gridTheme    : {},
    onMouseLeave : () => {},
  };


  constructor(props) {
    super(props);
    this.state = {
      data                  : [],
      stdDevs               : {},
      originalTimestamps    : {},
      readings              : [],
      timestamps            : [],
      dragTimestamps        : [],
      dragged               : null,
      activePointTimestamp  : null,
      customRangesLabelsRefs: {},
      customRangesSplitsRefs: {},
      flags                 : {},
      manualReadings        : [],
      firstTick             : null,
      lastTick              : null,
      maxValue              : 0,
      minValue              : 0,
      valuesScale           : [],
      isInProgress          : false,
      tickParts             : [],
      highReadings          : [],
      targetReadings        : [],
      lowReadings           : [],
      selectedPoint         : null,
    };
    this.timeSelector = createRef(null);
    this.timeRelatedDataSelector = createRef(null);
    this.valueSelector = createRef(null);
    this.chart = createRef(null);

    this.colors = {
      high       : '#F4C32C',
      target     : '#1EA98C',
      low        : '#F74053',
      line       : '#C9CFDC',
      plain      : '#30A8FF',
      placeholder: '#E0E8F2',
    };

    this.onThrottledCustomRangeDrag = throttle(
      (x, idx, scaleX, margin) => this.onCustomRangeDrag(x, idx, scaleX, margin),
      200,
    );

    this.onThrottledCustomRangeSplitDrag = throttle(
      (x, idx, scaleX, margin) => this.onCustomRangeSplitDrag(x, idx, scaleX, margin),
      200,
    );

    // Custom hour ranges
    this.minRange = HOUR_RANGE * 3600;
    this.maxRanges = 6;
    this.onSetWindowSize = this.onSetWindowSize.bind(this);
  }


  componentDidUpdate(props) {
    if (this.props.windowWidth !== props.windowWidth) {
      this.onSetWindowSize();
    }
  }


  onSetWindowSize() {
    if (process.env.BROWSER) {
      this.setState({
        windowWidth: this.props.windowWidth,
      });
    }
  }


  onAddNote(point, evt) {
    if (!this.props.onAddNote) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    const { x: timestamp, y: value } = point.data;
    const { conversion, isPlainValuesMode } = this.props;
    const originalTimestamp = get(this.state.originalTimestamps, timestamp, timestamp);
    const unitSymbol = isPlainValuesMode ? this.props.unitSymbol : conversion.unitSymbol;
    const fullUnitSymbol = unitSymbol && ` ${unitSymbol}`;
    const standardDeviation = get(this.state.stdDevs, timestamp);
    let status;
    if (includes(this.state.lowReadings, timestamp)) status = 'low';
    else if (includes(this.state.highReadings, timestamp)) status = 'high';
    else if (includes(this.state.targetReadings, timestamp)) status = 'target';
    this.props.onAddNote(originalTimestamp, value, standardDeviation, fullUnitSymbol, status);
  }


  onPointClick(point, evt) {
    const bounds = evt.currentTarget.getBoundingClientRect();

    const x = evt.clientX - bounds.left;
    const y = evt.clientY - bounds.top;

    const distanceX = point.x - x;
    const distanceY = point.y - y;

    const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);
    if (distance < 7.5) {
      this.onAddNote(point, evt);
      if (this.state.selectedPoint !== point.data.x) {
        this.setState({ selectedPoint: point.data.x });
        if (this.props.onPointClick) {
          this.props.onPointClick(point, evt);
        }
      } else {
        this.setState({ selectedPoint: null });
        if (this.props.onPointClick) {
          this.props.onPointClick(null, evt);
        }
      }
    } else {
      if (this.props.onPointClick) {
        this.props.onPointClick(null, evt);
      }
      this.setState({ selectedPoint: null });
    }
  }


  onCustomRangeDragStart(idx) {
    this.setState({ dragged: idx });
  }


  onCustomRangeDrag(x, idx, scaleX, margin) {
    const timestamp = this.getTimestampFromX(x, scaleX, margin);
    this.setState((prevState) => {
      const dragTimestamps = [...prevState.dragTimestamps];
      dragTimestamps[idx] = timestamp;
      return { dragTimestamps };
    });
  }


  onCustomRangeDragEnd(x, idx, scaleX, margin) {
    const timestamp = this.getTimestampFromX(x, scaleX, margin);
    this.props.onUpdateCustomRange(idx, timestamp);
    this.setState((prevState) => ({ timestamps: prevState.dragTimestamps, dragged: null }));
  }


  onCustomRangeSplitDrag(x, idx, scaleX, margin) {
    if (!this.state.customRangesSplitsRefs[idx]) {
      return;
    }
    const el = this.state.customRangesSplitsRefs[idx];
    const timestamp = this.getTimestampFromX(x, scaleX, margin);
    const momentDate = moment.unix(timestamp).utc();
    el.firstChild.firstChild.innerHTML = momentDate.format('LT');
  }


  onSplitCustomRange(x, idx, scaleX, margin) {
    const timestamp = this.getTimestampFromX(x, scaleX, margin);
    const { timestamps } = this.state;
    const prevTimestamp = timestamps[idx];
    const newSplitTimestamp = this.roundTimestamp(prevTimestamp + ((timestamp - prevTimestamp) / 2));
    const newSplitMomentDate = moment.unix(newSplitTimestamp).utc();
    timestamps.splice(idx + 1, 0, timestamp);
    const customRangesLabelsRefs = {};
    for (let i = 0; i < timestamps.length - 1; i++) {
      customRangesLabelsRefs[i] = createRef(null);
    }
    this.props.onSplitCustomRange(idx, timestamp);
    if (this.state.customRangesSplitsRefs[idx]) {
      delay(() => {
        this.state.customRangesSplitsRefs[idx].style.transform = 'translateX(0px)';
        this.state.customRangesSplitsRefs[idx].firstChild.firstChild.innerHTML = newSplitMomentDate.format('LT');
      }, 100);
    }
    return { timestamps, dragTimestamps: timestamps, customRangesLabelsRefs };
  }


  onRemoveCustomRange(idx) {
    this.setState((prevState) => {
      const { timestamps } = prevState;
      const cdx = idx || 1;
      timestamps.splice(cdx, 1);
      this.props.onRemoveCustomRange(idx);
      return { timestamps, dragTimestamps: timestamps };
    });
  }


  onCustomRangeLabelKeyDown(evt, idx) {
    // If ESC
    if (evt.keyCode === 27) {
      this.state.customRangesLabelsRefs[idx].current.blur();
      return;
    }

    if (evt.keyCode === 13) {
      this.props.onUpdateCustomRangeLabel(idx, evt.target.innerHTML);
      this.state.customRangesLabelsRefs[idx].current.blur();
    }
  }


  onMouseMove(point, evt) {
    const CONST_DISTANCE = 7.5;
    const { isReadOnly } = this.props;
    if (isReadOnly) {
      return;
    }
    const bounds = evt.currentTarget.getBoundingClientRect();

    const x = evt.clientX - bounds.left;
    const y = evt.clientY - bounds.top;

    const distanceX = point.x - x;
    const distanceY = point.y - y;

    const activePointTimestamp = get(point, 'data.x', null);

    const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);
    if (distance < CONST_DISTANCE) {
      if (this.chart.style.cursor !== 'pointer') {
        this.chart.style.cursor = 'pointer';
      }
      if (activePointTimestamp !== this.state.activePointTimestamp) {
        this.setState({ activePointTimestamp });
      }
    } else if (this.chart.style.cursor !== 'auto' && distance >= CONST_DISTANCE) {
      this.chart.style.cursor = 'auto';
      if (this.state.activePointTimestamp) {
        this.setState({ activePointTimestamp: null });
      }
    }

    if (this.props.onMouseMove) {
      this.props.onMouseMove(point);
    }
  }


  onMouseLeave(point, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ activePointTimestamp: null });
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave();
    }
  }


  onSetRelatedDataTimeRef(ref) {
    this.timeRelatedDataSelector = ref;
  }


  onMouseMoveChart(evt, margin, isRelatedData) {
    const { isReadOnly } = this.props;
    if (isReadOnly) {
      return;
    }

    const { maxValue, minValue } = this.state;
    const bounds = evt.currentTarget.getBoundingClientRect();

    const x = evt.clientX - bounds.left;
    const y = evt.clientY - bounds.top;
    if (y < margin.top || y > bounds.height - margin.bottom || x < margin.left || x > bounds.width - margin.right) {
      this.valueSelector.style.display = 'none';
      this.timeSelector.style.display = 'none';
      if (this.timeRelatedDataSelector) {
        this.timeRelatedDataSelector.style.display = 'none';
      }
    } else {
      this.timeSelector.style.display = 'block';
      if (this.timeRelatedDataSelector) {
        this.timeRelatedDataSelector.style.display = 'block';
      }

      this.timeSelector.style.left = `${x}px`;
      if (this.timeRelatedDataSelector) {
        this.timeRelatedDataSelector.style.left = `${x + 20}px`;
      }
      if (!isRelatedData) {
        this.valueSelector.style.display = 'flex';
        this.valueSelector.style.top = `${y}px`;

        const selectorValue = (maxValue - minValue)
        * ((1 - ((y - margin.top)) / (bounds.height - margin.bottom - margin.top))) + minValue;
        this.valueSelector.innerHTML = selectorValue.toFixed(0);
      }
    }
  }

  onMouseLeaveChart() {
    const { isReadOnly } = this.props;
    if (isReadOnly) {
      return;
    }
    this.valueSelector.style.display = 'none';
    this.timeSelector.style.display = 'none';
    if (this.timeRelatedDataSele) {
      this.timeRelatedDataSelector.style.display = 'none';
    }
  }


  get inProgressTheme() {
    return {
      textColor: this.colors.placeholder,
      axis     : {
        ticks: {
          line: {
            stroke     : this.colors.placeholder,
            strokeWidth: 1,
          },
          text: {},
        },
      },
      grid: {
        line: {
          stroke     : this.colors.placeholder,
          strokeWidth: 1,
        },
      },
    };
  }


  getTimestampFromX(x, scaleX, margin) {
    const timestamp = Math.round(scaleX.invert(x - margin.left + 15));
    return this.roundTimestamp(timestamp);
  }


  getBorderColor(line) {
    if (['averageHigh', 'averageLow', 'averageTarget'].includes(line.serieId)) {
      return 'average';
    }
    return 'transparent';
  }


  getPointColor(line) {
    if (this.state.isInProgress) { return this.colors.placeholder; }
    if (line.id === 'high') { return this.colors.high; }
    if (line.id === 'low') { return this.colors.low; }
    if (line.id === 'target') { return this.colors.target; }
    if (line.id === 'averageHigh') { return this.colors.high; }
    if (line.id === 'averageLow') { return this.colors.low; }
    if (line.id === 'averageTarget') { return this.colors.target; }
    return this.props.isPlainValuesMode ? (this.props.plainValuesColor || this.colors.plain) : 'transparent';
  }


  roundTimestamp(timestamp) {
    return Math.round(timestamp / 300) * 300; // 5 min step
  }


  renderCustomRangeDragButton(timestamps, idx, scaleX, margin) {
    const timestamp = timestamps[idx];
    const prevTimestamp = timestamps[idx - 1];
    const nextTimestamp = timestamps[idx + 1];
    const leftTimestamp = this.props.direction === 'ltr' ? prevTimestamp : nextTimestamp;
    const rightTimestamp = this.props.direction === 'ltr' ? nextTimestamp : prevTimestamp;

    const rawX = scaleX(timestamp);
    const mod = margin.left - 15;
    const x = rawX + mod;
    const minRange = this.props.direction === 'ltr' ? this.minRange : -this.minRange;

    const leftConstraint = (this.props.direction === 'ltr' && leftTimestamp + minRange < timestamp)
    || (this.props.direction === 'rtl' && leftTimestamp + minRange > timestamp)
      ? scaleX(leftTimestamp + minRange) - rawX
      : 0;
    const rightConstraint = (this.props.direction === 'ltr' && rightTimestamp - minRange > timestamp)
    || (this.props.direction === 'rtl' && rightTimestamp - minRange < timestamp)
      ? scaleX(rightTimestamp - minRange) - rawX
      : 0;

    return (
      <motionConfigContext.Consumer key={timestamp}>
        {
          (springConfig) => (
            <SmartMotion
              style={
                (spring) => ({
                  left: spring(x, springConfig),
                })
              }
            >
              {
                (style) => (
                  <motion.div
                    drag="x"
                    dragMomentum={false}
                    dragElastic={0}
                    dragConstraints={{ left: leftConstraint, right: rightConstraint }}
                    animate={{ z: this.state.dragged === idx ? 2 : 0 }}
                    initial={{ z: 0 }}
                    style={style}
                    className={styles.customRange__drag}
                    onDrag={(evt, info) => this.onThrottledCustomRangeDrag(x + info.point.x, idx, scaleX, margin)}
                    onDragEnd={(evt, info) => this.onCustomRangeDragEnd(x + info.point.x, idx, scaleX, margin)}
                    onDragStart={() => this.onCustomRangeDragStart(idx)}
                  >
                    <div className={styles.customRange__drag__btn}>
                      <DirectionHorizontalArrow className={styles.customRange__label__icon} />
                    </div>
                    <div className={styles.customRange__drag__line}><div /></div>
                  </motion.div>
                )
              }
            </SmartMotion>
          )
        }
      </motionConfigContext.Consumer>
    );
  }


  renderCustomRangeSplitButton(timestamps, idx, scaleX, margin) {
    const timestamp = timestamps[idx];
    const nextTimestamp = timestamps[idx + 1];

    if (timestamps.length - 1 >= this.maxRanges || nextTimestamp - timestamp < this.minRange * 2) {
      return null;
    }

    const splitTimestamp = this.roundTimestamp(timestamp + ((nextTimestamp - timestamp) / 2));

    const mod = margin.left - 15;
    const rawX = scaleX(splitTimestamp);
    const x = rawX + mod;

    const leftTimestamp = this.props.direction === 'ltr' ? timestamp : nextTimestamp;
    const rightTimestamp = this.props.direction === 'ltr' ? nextTimestamp : timestamp;
    const minRange = this.props.direction === 'ltr' ? this.minRange : -this.minRange;

    const leftConstraint = scaleX(leftTimestamp + minRange) - rawX;
    const rightConstraint = scaleX(rightTimestamp - minRange) - rawX;

    const momentDate = moment.unix(splitTimestamp).utc();

    return (
      <motionConfigContext.Consumer key={`s${idx}`}>
        {
          (springConfig) => (
            <SmartMotion
              style={
                (spring) => ({
                  left: spring(x, springConfig),
                })
              }
            >
              {
                (style) => (
                  <motion.div
                    drag="x"
                    dragMomentum={false}
                    dragElastic={0}
                    dragConstraints={{ left: leftConstraint, right: rightConstraint }}
                    initial={{ z: 2 }}
                    style={style}
                    className={cn(styles.customRange__drag, styles['customRange__drag--noHeight'])}
                    ref={
                      (el) => {
                        if (el && el !== this.state.customRangesSplitsRefs[idx]) {
                          this.setState((prevState) => {
                            const customRangesSplitsRefs = { ...prevState.customRangesSplitsRefs };
                            customRangesSplitsRefs[idx] = el;
                            return { customRangesSplitsRefs };
                          });
                        }
                      }
                    }
                    onDrag={(evt, info) => this.onThrottledCustomRangeSplitDrag(x + info.point.x, idx, scaleX, margin)}
                    onDragEnd={(evt, info) => this.onSplitCustomRange(x + info.point.x, idx, scaleX, margin)}
                  >
                    <div className={styles.customRange__drag__btn}>
                      <div className={styles.customRange__drag__valueInfo}>{ momentDate.format('LT') }</div>
                      <Split className={styles.customRange__label__icon} />
                    </div>
                  </motion.div>
                )
              }
            </SmartMotion>
          )
        }
      </motionConfigContext.Consumer>
    );
  }


  renderCustomRangeDragButtons(timestamps, scaleX, margin) {
    if (!this.props.isCustomRanges || this.props.isReadOnly) {
      return null;
    }
    const buttons = [];
    for (let i = 0; i < timestamps.length - 1; i++) {
      if (i) {
        buttons.push(this.renderCustomRangeDragButton(timestamps, i, scaleX, margin));
      }
      buttons.push(this.renderCustomRangeSplitButton(timestamps, i, scaleX, margin));
    }
    return buttons;
  }


  renderCustomRangeCustomLabel(idx) {
    const { isReadOnly } = this.props;
    let labelText = null;
    if (this.props.customRangesLabels[idx]) {
      labelText = this.props.customRangesLabels[idx];
    }
    if (isReadOnly) {
      if (labelText) {
        return (
          <div className={`${styles.customRange__label__textLine} mt-1`}>
            { labelText }
          </div>
        );
      }
      return null;
    }
    return (
      <div className={`${styles.customRange__label__textLine} ${styles.customRange__label__customLabel}`}>
        <ContentEditable
          innerRef={this.state.customRangesLabelsRefs[idx]}
          tagName="span"
          html={labelText || ''}
          className={styles.customRange__label__contentEditable}
          disabled={isReadOnly}
          onKeyDown={(evt) => this.onCustomRangeLabelKeyDown(evt, idx)}
        />
        <Button
          styleModifier="transparent"
          className={
            cn(styles.customRange__label__action, styles.customRange__label__edit, {
              'd-none': this.state.customRangesLabelsRefs[idx]
              && document.activeElement === this.state.customRangesLabelsRefs[idx].current,
            })
          }
          onClick={() => this.state.customRangesLabelsRefs[idx].current.focus()}
        >
          <Pencil className={styles.customRange__label__icon} />
        </Button>
      </div>
    );
  }


  renderRemoveCustomRangeBtn(tsLength, idx) {
    const { isReadOnly } = this.props;
    if (tsLength <= 2 || isReadOnly) {
      return null;
    }
    return (
      <div className={styles.customRange__label__actions}>
        <Button
          styleModifier="transparent"
          className={cn(styles.customRange__label__action)}
          onClick={() => this.onRemoveCustomRange(idx)}
        >
          <ThrashBin className={styles.customRange__label__icon} />
        </Button>
      </div>
    );
  }


  renderCustomRangeLabel(timestamps, idx, scaleX) {
    const tsLength = timestamps.length;
    const value = timestamps[idx];
    const nextValue = get(timestamps, idx + 1);
    const valueX = scaleX(value);
    const nextValueX = scaleX(nextValue);
    const rangeX = Math.abs(nextValueX - valueX);
    const fromHour = moment.unix(value).utc().format('LT');
    const toHour = moment.unix(nextValue).utc().format('LT');

    return (
      <motionConfigContext.Consumer key={value}>
        {
          (springConfig) => (
            <SmartMotion
              style={
                (spring) => ({
                  width: spring(rangeX, springConfig),
                })
              }
            >
              {
                (style) => (
                  <div className={styles.customRange__label} style={style}>
                    <div className={styles.customRange__label__textLine}>
                      { `${fromHour} - ${toHour}` }
                      { this.renderRemoveCustomRangeBtn(tsLength, idx) }
                    </div>
                    { this.renderCustomRangeCustomLabel(idx) }
                  </div>
                )
              }
            </SmartMotion>
          )
        }
      </motionConfigContext.Consumer>
    );
  }


  renderCustomRangeLabels(width, timestamps, scaleX, margin) {
    if (!this.props.isCustomRanges) {
      return null;
    }
    const { dragTimestamps } = this.state;
    const labels = [];
    for (let i = 0; i < dragTimestamps.length - 1; i++) {
      labels.push(this.renderCustomRangeLabel(dragTimestamps, i, scaleX, margin));
    }

    return (
      <motionConfigContext.Consumer>
        {
          (springConfig) => (
            <SmartMotion
              style={
                (spring) => ({
                  width       : spring(width, springConfig),
                  paddingLeft : margin.left,
                  paddingRight: margin.right,
                })
              }
            >
              { (style) => <div className={styles.customRange__labels} style={style}>{ labels }</div> }
            </SmartMotion>
          )
        }
      </motionConfigContext.Consumer>
    );
  }


  renderTargetZone(props) {
    if (this.state.isInProgress) {
      return null;
    }
    const { conversion, standards } = this.props;
    const lowPreMealThreshold = conversion.toDisplay(standards.preMeal.lowThreshold);
    const highPreMealThreshold = conversion.toDisplay(standards.preMeal.highThreshold);
    const lowPostMealThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
    const highPostMealThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
    const criticalLow = this.props.criticalLow ? conversion.toDisplay(this.props.criticalLow) : 0;
    const criticalHigh = this.props.criticalHigh ? conversion.toDisplay(this.props.criticalHigh) : 0;
    const width = props.width - props.margin.right - props.margin.left;
    const yTopPreMeal = props.yScale(highPreMealThreshold);
    const yBottomPreMeal = props.yScale(lowPreMealThreshold);
    const yTopPostMeal = props.yScale(highPostMealThreshold);
    const yBottomPostMeal = props.yScale(lowPostMealThreshold);
    const yTopCriticalHigh = props.yScale(criticalHigh);
    const yBottomCriticalLow = props.yScale(criticalLow);

    return (
      <motionConfigContext.Consumer>
        {
          (springConfig) => (
            <SmartMotion
              key="bloodGlucoseConcentrationTargetZone"
              style={
                (spring) => ({
                  width: spring(width, springConfig),
                })
              }
            >
              {
                (style) => (
                  <g>
                    <rect
                      y={yTopPreMeal}
                      width={style.width}
                      height={yBottomPreMeal - yTopPreMeal}
                      fill="rgba(48, 235, 158, 0.15)"
                    />
                    <rect
                      y={yTopPostMeal}
                      width={style.width}
                      height={yBottomPostMeal - yTopPostMeal}
                      fill="rgba(48, 235, 158, 0.1)"
                    />
                    {
                      this.props.criticalHigh && (
                        <line
                          x1="0"
                          y1={yTopCriticalHigh}
                          x2={style.width}
                          y2={yTopCriticalHigh}
                          style={{ stroke: this.colors.high }}
                        />
                      )
                    }
                    {
                      this.props.criticalLow && (
                        <line
                          x1="0"
                          y1={yBottomCriticalLow}
                          x2={style.width}
                          y2={yBottomCriticalLow}
                          style={{ stroke: this.colors.low }}
                        />
                      )
                    }
                    <line
                      x1="0"
                      y1={yTopPostMeal}
                      x2={style.width}
                      y2={yTopPostMeal}
                      style={{ stroke: this.colors.high }}
                    />
                    <line
                      strokeDasharray="10, 5"
                      x1="0"
                      y1={yTopPreMeal}
                      x2={style.width}
                      y2={yTopPreMeal}
                      style={{ stroke: this.colors.high }}
                    />
                    <line
                      x1="0"
                      y1={yBottomPostMeal}
                      x2={style.width}
                      y2={yBottomPostMeal}
                      style={{ stroke: this.colors.low }}
                    />
                    <line
                      strokeDasharray="10, 5"
                      x1="0"
                      y1={yBottomPreMeal}
                      x2={style.width}
                      y2={yBottomPreMeal}
                      style={{ stroke: this.colors.low }}
                    />
                  </g>
                )
              }
            </SmartMotion>
          )
        }
      </motionConfigContext.Consumer>
    );
  }


  renderTooltip(point) {
    if (this.props.disableTooltip) {
      return null;
    }

    if (this.state.isInProgress) {
      return null;
    }
    const { x: timestamp, y: value } = point.data;
    const { conversion, direction, isPlainValuesMode } = this.props;
    const { dateFormat, timeFormat } = this.props.tooltipConfig;
    const unitSymbol = isPlainValuesMode ? this.props.unitSymbol : conversion.unitSymbol;
    const stdDev = get(this.state.stdDevs, timestamp);
    const isManual = includes(this.state.manualReadings, timestamp);
    const stdDevElement = isUndefined(stdDev)
      ? null
      : <p className="text--large text--primary">{ `(${stdDev} ${unitSymbol})` }</p>;

    const isLow = includes(this.state.lowReadings, timestamp);
    const isHigh = includes(this.state.highReadings, timestamp);
    const isTarget = includes(this.state.targetReadings, timestamp);

    const originalTimestamp = get(this.state.originalTimestamps, timestamp);
    const momentDate = moment.unix(originalTimestamp || timestamp).utc();
    const flag = get(this.state.flags, timestamp, 'None');

    return (
      <div className={styles.tooltip}>
        <div>
          <div className={
            cn('text--large text--bold d-flex align-items-center justify-content-between',
              {
                [styles.tooltipHeader__high]  : isHigh,
                [styles.tooltipHeader__target]: isTarget,
                [styles.tooltipHeader__low]   : isLow,
              })
          }
          >
            <span>{ value }
              <span className="text--small mx-1">
                { unitSymbol }
                { isManual && <span className="mx-1">(<FormattedMessage {...messages.manual} />)</span> }
              </span>
            </span>
            <div className={styles.tooltip__flagIconContainer}>
              <ReadingFlagIcon flag={flag} />
            </div>
          </div>
          { stdDevElement }
          {
            dateFormat || timeFormat
              ? (
                <p className="text--light row flex-nowrap justify-content-between" style={{ direction }}>
                  { dateFormat && <span className="col-auto">{ momentDate.format(dateFormat) }</span> }
                  {
                    timeFormat
                    && <span className={`col-auto ${styles.tooltip__time}`}>{ momentDate.format(timeFormat) }</span>
                  }
                </p>
              )
              : null
          }
        </div>
      </div>
    );
  }


  renderStdDev(x, scaleY, color) {
    const stdDev = get(this.state.stdDevs, x);
    if (!stdDev) {
      return null;
    }
    const halfLine = scaleY(stdDev / 2);
    return (
      <g>
        <line
          x1={0}
          y1={halfLine}
          x2={0}
          y2={-halfLine}
          style={{ stroke: color, strokeWidth: 2 }}
        />
        <line
          x1={-3}
          y1={halfLine}
          x2={3}
          y2={halfLine}
          style={{ stroke: color, strokeWidth: 2 }}
        />
        <line
          x1={-3}
          y1={-halfLine}
          x2={3}
          y2={-halfLine}
          style={{ stroke: color, strokeWidth: 2 }}
        />
      </g>
    );
  }


  /* eslint-disable no-mixed-operators */
  renderPoint({ size, color, borderColor, borderWidth, datum }, scaleY, chartWidth) {
    if (color === 'transparent') {
      return null;
    }
    const { highlightedReadings } = this.props;
    const { selectedPoint } = this.state;
    const { x } = datum;
    const isActive = x === this.state.activePointTimestamp;
    size = isActive ? size * 1.4 : size;
    const isSelected = selectedPoint === x;
    let point;
    if (includes(this.state.manualReadings, x)) {
      const highlight = highlightedReadings && highlightedReadings.some((item) => item.timestamp === x);
      const halfSize = size / 2;
      point = (
        <g transform={`translate(-${halfSize} -${halfSize}) rotate(45 ${halfSize} ${halfSize})`}>
          {
            isSelected
              && (
                <rect
                  y={-halfSize}
                  x={-halfSize}
                  width={size + size}
                  height={size + size}
                  fill={color}
                  style={{ pointerEvents: 'none', opacity: 0.3 }}
                />
              )
          }
          <rect
            width={size}
            height={size}
            fill={color}
            stroke={highlight ? 'black' : borderColor}
            strokeWidth={highlight ? 1 : borderWidth}
            style={{ pointerEvents: 'none' }}
          />
        </g>
      );
    } else if (borderColor === 'average') {
      const { intl, conversion, isPlainValuesMode } = this.props;
      const unitSymbol = isPlainValuesMode ? this.props.unitSymbol : conversion.unitSymbol;
      const chartPoint = this.state.data[4].data.find((item) => item.x.toString() === x.toString());
      if (!chartPoint) return null;
      const value = chartPoint.y;
      const timestamp = chartPoint.x;
      const tickPart = this.state.tickParts.find((part) => timestamp >= part.start && timestamp <= part.end);
      if (tickPart) {
        point = (
          <g>
            <text
              x="0"
              y="-5"
              fontSize="10"
              fill="black"
              style={{ pointerEvents: 'none', transform: `translateX(-${chartWidth * tickPart.width / 2}px)` }}
            >
              { `${intl.formatMessage(messages.average)}: ${value} ${unitSymbol}` }
            </text>
            <rect
              y={-size / 4}
              width={chartWidth * tickPart.width}
              height={size / 2}
              fill={color}
              stroke={borderColor}
              strokeWidth={borderWidth}
              style={{ pointerEvents: 'none', transform: `translateX(-${chartWidth * tickPart.width / 2}px)` }}
            />
          </g>
        );
      }
    } else {
      const highlight = highlightedReadings && highlightedReadings.some((item) => item.timestamp === x);
      point = (
        <>
          {
            isSelected
              && (
                <circle
                  r={size}
                  fill={color}
                  style={{ pointerEvents: 'none', opacity: 0.3 }}
                />
              )
          }
          <circle
            r={size / 2}
            fill={color}
            stroke={highlight ? 'black' : borderColor}
            strokeWidth={highlight ? 1 : borderWidth}
            style={{ pointerEvents: 'none' }}
          />
        </>
      );
    }
    return (
      <g>
        { this.renderStdDev(x, scaleY, color) }
        { point }
      </g>
    );
  }


  renderLines({ width, height, margin }) {
    const { direction } = this.props;
    const { top, right, bottom, left } = margin;
    const chartWidth = width - left - right;
    const chartHeight = height - top - bottom;
    const sideLinePosition = direction === 'ltr' ? 0 : chartWidth;
    return (
      <g>
        <line
          x1={sideLinePosition}
          y1={0}
          x2={sideLinePosition}
          y2={chartHeight}
          style={{ stroke: this.colors.placeholder, strokeWidth: 1 }}
        />
        <line
          x1={0}
          y1={chartHeight}
          x2={chartWidth}
          y2={chartHeight}
          style={{ stroke: this.colors.placeholder, strokeWidth: 1 }}
        />
      </g>
    );
  }


  render() {
    const { data, timestamps, firstTick, lastTick, maxValue, minValue, valuesScale, isInProgress } = this.state;
    const { direction, isLineEnabled, isGridXEnabled, isPlainValuesMode, isReadOnly } = this.props;
    const margin = {
      top   : 20,
      right : 50,
      bottom: 50,
      left  : 50,
    };
    const marginX = margin.left + margin.right;
    const marginY = margin.top + margin.bottom;
    const axisY = {
      tickSize    : 5,
      tickPadding : 5,
      tickRotation: 0,
      tickValues  : valuesScale,
    };
    const lineColor = isInProgress ? this.colors.placeholder : this.colors.line;

    let theme = {
      crosshair: {
        line: {
          stroke         : '#30A8FF',
          strokeWidth    : 1,
          strokeDasharray: null,
        },
      },
      axis: {
        ticks: {
          line: {
            stroke     : this.colors.placeholder,
            strokeWidth: 1,
          },
        },
      },
    };
    if (isInProgress) {
      theme = this.inProgressTheme;
    }

    /*
      IMPORTANT: Because Edge doesn't support `dominant-baseline` and RTL support is poor
      hardcoded styles overwrite ticks labels transform for current tickPadding and tickRotation values and
      must be correlated if change
     */

    return (
      <>
        <div className={cn('nivoChart', styles.root, { [styles['root--readOnly']]: isReadOnly })}>
          <div
            className={
              cn('nivoChart__inner', styles.root__inner, {
                fadingLoader                         : isInProgress,
                [styles['root__inner--activeNoting']]: this.props.onAddNote,
              })
            }
            ref={(chart) => { this.chart = chart; }}
            onMouseLeave={(evt) => this.onMouseLeaveChart(evt)}
            onMouseMove={(evt) => this.onMouseMoveChart(evt, margin)}
          >
            <AutoSizer>
              {
                ({ height, width }) => {
                  const xGap = (width - margin.right - margin.left) / (timestamps.length - 1);
                  const scaleX = scaleLinear()
                    .rangeRound([0, width - marginX])
                    .domain(direction === 'rtl' ? [lastTick, firstTick] : [firstTick, lastTick]);
                  const scaleY = scaleLinear().rangeRound([0, height - marginY]).domain([minValue, maxValue]);

                  return (
                    <ThemeProvider theme={theme}>
                      <Line
                        data={data}
                        height={height}
                        width={width}
                        margin={margin}
                        xScale={{ type: 'linear', min: firstTick, max: lastTick, reverse: direction === 'rtl' }}
                        yScale={{ type: 'linear', min: minValue, max: maxValue }}
                        axisTop={null}
                        axisRight={direction === 'ltr' ? null : axisY}
                        axisBottom={
                          {
                            tickSize    : 0,
                            tickPadding : 10,
                            tickRotation: 0,
                            tickValues  : timestamps,
                            format      : (value) => (
                              this.props.formatLabel
                                ? this.props.formatLabel(value, firstTick, lastTick, xGap)
                                : formatTimestamp(value)
                            ),
                          }
                        }
                        axisLeft={direction === 'rtl' ? null : axisY}
                        enableGridX={isGridXEnabled}
                        enableGridY={this.props.enableGridY}
                        gridXValues={timestamps}
                        gridYValues={valuesScale}
                        colors={[lineColor, 'transparent', 'transparent', 'transparent']}
                        theme={{ ...theme, ...this.props.gridTheme }}
                        enableArea
                        areaOpacity={0.1}
                        lineWidth={1}
                        pointSize={9}
                        pointBorderColor={this.getBorderColor}
                        pointSymbol={(props) => this.renderPoint(props, scaleY, width - marginX)}
                        pointColor={(line) => this.getPointColor(line)}
                        pointBorderWidth={0}
                        crosshairType={direction === 'ltr' ? 'bottom-left' : 'bottom-right'}
                        useMesh
                        enableCrosshair={false}
                        tooltip={({ point }) => this.renderTooltip(point)}
                        layers={
                          [
                            'grid', 'markers', 'axes',
                            (isLineEnabled ? 'areas' : null),
                            (isPlainValuesMode ? null : this.renderTargetZone.bind(this)),
                            (isInProgress ? null : 'crosshair'),
                            (isLineEnabled ? 'lines' : null),
                            'points', 'slices', 'mesh', 'legends',
                            this.renderLines.bind(this),
                          ]
                        }
                        onMouseMove={(point, evt) => this.onMouseMove(point, evt)}
                        onClick={(point, evt) => this.onPointClick(point, evt)}
                        onMouseLeave={(point, evt) => this.onMouseLeave(point, evt)}
                        isInteractive={this.props.isReadOnly}
                      />
                      {
                        !isReadOnly && (
                          <>
                            <div
                              ref={(timeSelector) => { this.timeSelector = timeSelector; }}
                              className={styles.timeSelector__chart}
                            />
                            <div
                              ref={(valueSelector) => { this.valueSelector = valueSelector; }}
                              className={styles.valueSelector}
                            />
                          </>
                        )
                      }
                      { this.renderCustomRangeDragButtons(timestamps, scaleX, margin) }
                      { this.renderCustomRangeLabels(width, timestamps, scaleX, margin) }
                    </ThemeProvider>
                  );
                }
              }
            </AutoSizer>
          </div>
        </div>
        {
          this.props.showRelatedData
          && (
            <BloodGlucoseConcentrationChartRelatedData
              isReadOnly={isReadOnly}
              firstTick={this.state.firstTick}
              lastTick={this.state.lastTick}
              direction={direction}
              relatedData={this.props.relatedData}
              timeSeriesResources={this.props.timeSeriesResources}
              onSetRelatedDataTimeRef={(ref) => this.onSetRelatedDataTimeRef(ref)}
              onMouseMoveChart={(evt, chartMargin) => this.onMouseMoveChart(evt, chartMargin, true)}
              measurements={this.props.measurements}
              disableTooltip={this.props.disableTooltip}
              onMouseLeave={(evt) => this.onMouseLeaveChart(evt)}
            />
          )
        }
      </>
    );
  }

}


const mapStateToProps = (state) => ({
  windowWidth      : windowWidth(state),
  activeMeasurement: selectors.getMeasurement(state),
});

const ConnectedBloodGlucoseConcentrationChart = connect(
  mapStateToProps,
)(BloodGlucoseConcentrationChart);


export default withStyles(styles)(injectIntl(ConnectedBloodGlucoseConcentrationChart));
