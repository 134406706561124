import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import map from 'lodash/map';
import times from 'lodash/times';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import first from 'lodash/first';
import sortBy from 'lodash/sortBy';
import {
  calculateRelatedValue,
  getUnitMedication,
  getUniqRelatedData,
  translateMeal,
  translateReadingRelatedData,
} from 'helpers/relatedData';
import { isSameDay } from 'helpers/datetime';
import { isAggregatedPostMeal } from 'libs/StatsCalculations';
import Button from 'components/Form/Button';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import Link from 'components/Link';
import intlShape from 'shapes/intlShape';
import ActivityIcon from 'svg/activity.svg';
import MealIcon from 'svg/meal.svg';
import InjectionIcon from 'svg/injection.svg';
import AverageIcon from 'svg/average-blood.svg';
import PillsIcon from 'svg/pills.svg';
import ChevronRight from 'svg/chevron-right.svg';
import App from 'modules/App';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import DailyReadingsDistribution from './DailyReadingsDistribution';
import styles from './ReadingsTable.pcss';


class ReadingsTable extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { readings, relatedData, timeSeriesResources, activeReadingFromState, printMode } = props;
    const activeReadingTimestamp = activeReadingFromState ? activeReadingFromState.timestamp : 0;

    const activeRowMomentDate = activeReadingTimestamp ? moment.unix(activeReadingTimestamp).utc() : null;
    const translateMomentDate = (momentDate) => {
      momentDate.startOf('day');
      return momentDate.locale('en--account');
    };

    const activeRowTimestamp = activeRowMomentDate
      ? +translateMomentDate(activeRowMomentDate).format('X')
      : null;

    if (readings === state.readings) {
      return {
        activeRow    : activeRowTimestamp || state.rowsIndex[0] || null,
        activeReading: activeReadingTimestamp,
      };
    }


    const timeSeriesResourcesMeals = filter(
      flatten(
        map(timeSeriesResources, 'resources'),
      ),
      { resourceType: 'Meal' },
    );
    const readingsTimestamps = map(readings, 'timestamp');
    const relatedDataTimestamps = map(relatedData, 'deviceDateTime');
    const timeSeriesResourcesTimestamps = map(timeSeriesResourcesMeals, 'timestamp');
    const timestampsSet = new Set([...readingsTimestamps, ...relatedDataTimestamps, ...timeSeriesResourcesTimestamps]);

    const rowsIndex = [];
    const rows = {};

    forEach(Array.from(timestampsSet.values()), (timestamp) => {
      if (timestamp) {
        const rowMoment = moment.unix(timestamp).utc();
        const rowTimestamp = +translateMomentDate(rowMoment).format('X');
        const isDayAlreadyIncluded = find(rowsIndex, (rowIndex) => isSameDay(rowIndex, rowTimestamp));
        if (!isDayAlreadyIncluded) {
          rowsIndex.push(rowTimestamp);
          rows[rowTimestamp] = {
            readings           : filter(readings, (reading) => isSameDay(rowTimestamp, reading.timestamp)),
            // eslint-disable-next-line max-len
            relatedData        : filter(relatedData, (data) => isSameDay(rowTimestamp, data.deviceDateTime || data.dateTime)),
            // eslint-disable-next-line max-len
            timeSeriesResources: filter(timeSeriesResourcesMeals, (timeSeriesResource) => isSameDay(rowTimestamp, timeSeriesResource.timestamp)),
          };
        }
      }
    });

    rowsIndex.sort((a, b) => b - a);
    const isShowAll = printMode ? true : state.isShowAll;
    return {
      readings,
      rowsIndex,
      rows,
      activeRow    : activeRowTimestamp || rowsIndex[0] || null,
      activeReading: activeReadingTimestamp,
      isShowAll,
    };
  }


  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    isReadOnly            : PropTypes.bool,
    isInProgress          : PropTypes.bool,
    readings              : PropTypes.array,
    relatedData           : PropTypes.array,
    timeSeriesResources   : PropTypes.array,
    activeReadingTimestamp: PropTypes.number,
    // Explicit props
    onCellClick           : PropTypes.func,
    // Implicit props
    printMode             : PropTypes.bool,
    intl                  : intlShape,
    featureToggles        : PropTypes.array,
    openDropdownId        : PropTypes.string,
    // Implicit actions
    onOpenDropdown        : PropTypes.func,
    onCloseDropdown       : PropTypes.func,
    activeReadingFromState: PropTypes.object,
  };


  static defaultProps = {
    onCellClick: () => { },
  };


  constructor(props) {
    super(props);
    this.state = {
      rowsIndex      : [],
      rows           : {},
      isShowAll      : false,
      showRelatedData: true,
    };
  }


  async onOpenPills(evt) {
    evt.preventDefault();
    const isOpen = this.props.openDropdownId === constants.PILLS_DROPDOWN;
    await this.props.onCloseDropdown();
    if (!isOpen) {
      this.props.onOpenDropdown(constants.PILLS_DROPDOWN);
    }
  }


  onToggleShowMore() {
    this.setState((prevState) => ({ isShowAll: !prevState.isShowAll }));
  }


  async onChangeShowRelatedData(input) {
    await this.setState({ showRelatedData: input.value });
  }


  renderPillsItems(dropDownItems) {
    const { intl, openDropdownId } = this.props;
    if (openDropdownId !== constants.PILLS_DROPDOWN) {
      return null;
    }

    return (
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className={styles.dropdown}
      >
        <div>
          {
            dropDownItems.map((item, index) => (
              <div key={`pills-dropdown-${item.name}-item-${item.id}`}>
                <div className={styles.dropdownPills__item}>
                  <div>{ item.name }</div>
                  <div>
                    <span>
                      { `${item.value} ${intl.formatMessage(messages.labels[`${getUnitMedication(item)}Param`])}` }
                    </span>
                    <span className={styles.dropdownPills__date}>
                      { moment.unix(item.timestamp).utc().format('LT') }
                    </span>
                  </div>
                </div>
                { dropDownItems.length - 1 > index && <hr className={styles.hr} /> }
              </div>
            ))
          }
        </div>
      </motion.div>
    );
  }


  renderRelatedDataItem(timestamp, values, name, dropDownItems) {
    const { conversion } = this.props;

    let icon;
    let label;
    let unit;
    switch (name) {
      case 'average':
        icon = <AverageIcon />;
        label = <FormattedMessage {...messages.kpi.average} />;
        unit = conversion.unitSymbol;
        break;
      case 'medications':
        icon = <InjectionIcon />;
        label = <FormattedMessage {...messages.labels.injection} />;
        unit = <FormattedMessage {...messages.labels.injectionUnit} />;
        break;
      case 'foods':
        icon = <MealIcon />;
        label = <FormattedMessage {...messages.labels.food} />;
        unit = <FormattedMessage {...messages.labels.foodUnit} />;
        break;
      case 'activities':
        icon = <ActivityIcon />;
        label = <FormattedMessage {...messages.labels.activity} />;
        unit = <FormattedMessage {...messages.labels.activityUnit} />;
        break;
      case 'pills':
        icon = <PillsIcon />;
        label = <FormattedMessage {...messages.labels.medications} />;
        unit = <FormattedMessage {...messages.labels.count} />;
        break;
      default:
        break;
    }
    return (
      <div key={name} className={styles.relatedData__container}>
        <div
          className={cn(styles.relatedData__iconsWrapper)}
          key={`${timestamp}-render-related-data-${name}`}
        >
          <div className={styles.relatedData__unit}>
            { icon } { label } ({ unit })
          </div>
          <div className={cn(styles.relatedData__valueWrapper, 'd-flex align-items-center justify-content-between')}>
            {
              map(values, (value, index) => (
                <div key={`${timestamp}-reading-tooltip-related-${name}-value-${value}-${index}`}>
                  { name === 'average' && <FormattedMessage {...messages.abbr.avg} /> }{ ` ${value}` }
                </div>
              ))
            }
          </div>
        </div>
        {
          !isEmpty(dropDownItems) && !this.props.isReadOnly
          && (
            <div className={styles.dropdown__container}>
              <Link to="" className={styles.button__pills} onClick={(evt) => this.onOpenPills(evt)}>
                <ChevronRight />
              </Link>
              <AnimatePresence>
                { this.renderPillsItems(dropDownItems) }
              </AnimatePresence>
            </div>
          )
        }
      </div>
    );
  }


  renderRelatedDataValue(row, timestamp) {
    const { showRelatedData } = this.state;
    if (!row || !showRelatedData) return null;
    const { readings = [], relatedData = [], mappedRelatedData = [] } = row;
    const components = [];
    let activityValues = 0;
    let foodValues = 0;
    let medicationFastValues = 0;
    let medicationLongValues = 0;
    let medicationPillsValues = 0;
    let medicationPillsItems = [];
    let sumValue = 0;

    forEach(readings, (reading) => {
      sumValue += this.props.conversion.toDisplay(reading.value);
    });

    forEach(relatedData, (data) => {
      const {
        activityValue,
        medicationFastValue,
        medicationLongValue,
        pillsCount,
        pillsItems,
      } = calculateRelatedValue(data);

      activityValues += activityValue;
      medicationFastValues += medicationFastValue;
      medicationLongValues += medicationLongValue;
      medicationPillsValues += pillsCount;

      const pillsItemsWithTimestamp = pillsItems.map((pillItem) => ({
        ...pillItem,
        timestamp: data.deviceDateTime,
      }));
      medicationPillsItems = medicationPillsItems.concat(pillsItemsWithTimestamp);
    });

    forEach(mappedRelatedData, (rd) => {
      if (rd.type !== 'food') return;
      foodValues += rd.carbs;
    });

    const averageValue = readings && readings.length
      ? (sumValue / readings.length).toFixed(2)
      : 0;

    components.push(this.renderRelatedDataItem(timestamp, [averageValue], 'average'));
    components.push(this.renderRelatedDataItem(timestamp, [medicationFastValues, medicationLongValues], 'medications'));
    components.push(this.renderRelatedDataItem(timestamp, [foodValues], 'foods'));
    components.push(this.renderRelatedDataItem(timestamp, [activityValues], 'activities'));
    components.push(this.renderRelatedDataItem(timestamp, [medicationPillsValues], 'pills', medicationPillsItems));

    return (
      <div className={styles.relatedData__wrapper}>
        { components }
      </div>
    );
  }


  renderMeasurementPlaceholder(idx) {
    return (
      <div key={idx} className="col-auto">
        <div className={cn(styles.reading, styles['reading--placeholder'])}>
          <div className={styles.reading__data}>
            <p className={styles.lead} />
            <p className={styles.desc} />
          </div>
        </div>
      </div>
    );
  }


  renderRow(rowTimestamp) {
    const { conversion, standards, activeReadingFromState } = this.props;
    const activeReadingTimestamp = activeReadingFromState ? activeReadingFromState.timestamp : 0;
    const { rows } = this.state;
    const momentDate = moment.unix(rowTimestamp).utc();
    const row = get(rows, rowTimestamp);
    const readings = get(rows, `${rowTimestamp}.readings`, []);

    const mappedReadings = map(readings, (reading) => {
      const { timestamp, value, flags: flag } = reading;

      const convertedValue = conversion.toDisplay(value);
      const highConverted = isAggregatedPostMeal(reading.flags)
        ? conversion.toDisplay(standards.postMeal.highThreshold)
        : conversion.toDisplay(standards.preMeal.highThreshold);
      const lowConverted = isAggregatedPostMeal(reading.flags)
        ? conversion.toDisplay(standards.postMeal.lowThreshold)
        : conversion.toDisplay(standards.preMeal.lowThreshold);

      let type = 'normal';
      if ((convertedValue > highConverted)) {
        type = 'high';
      } else if ((convertedValue < lowConverted)) {
        type = 'low';
      }

      return {
        timestamp,
        type,
        flag,
        value,
        displayValue: conversion.unit === 'MMOL_L'
          ? convertedValue
          : Math.round(convertedValue),
        isActive: activeReadingTimestamp === timestamp,
      };
    });


    let fullMappedRelatedData = [];
    if (row) {
      const relatedData = get(row, 'relatedData', []);
      const mappedRelatedData = flatten(map(relatedData, translateReadingRelatedData));
      const timeSeriesResources = get(row, 'timeSeriesResources');
      const mappedTimeSeriesResources = map(timeSeriesResources, translateMeal);
      const uniqRelatedData = getUniqRelatedData(mappedRelatedData, mappedTimeSeriesResources);
      fullMappedRelatedData = sortBy([...uniqRelatedData, ...mappedTimeSeriesResources], 'timestamp');
      row.mappedRelatedData = fullMappedRelatedData;
    }

    return (
      <li
        key={rowTimestamp}
        className={cn('row', styles.row, 'ReadingsTableRow')}
      >
        <div className={cn('row', styles.relatedData__firstRow, 'ReadingsTableRowRelatedData')}>
          <div className="col-auto" style={{ padding: 0 }}>
            <div className={styles.day}>
              <p className={styles.lead}>{ momentDate.format('dddd') }</p>
              <p className={styles.desc}>{ momentDate.format('L') }</p>
            </div>
          </div>
          <div className={styles.relatedData__row}>
            <div className="row" style={{ width: '100%' }}>
              { this.renderRelatedDataValue(row, rowTimestamp) }
            </div>
          </div>
        </div>
        <DailyReadingsDistribution
          index={rowTimestamp}
          readings={mappedReadings}
          relatedData={fullMappedRelatedData}
          conversion={conversion}
        />
      </li>
    );
  }


  renderRowPlaceholder() {
    return (
      <li className={cn('row fadingLoader', styles.row)}>
        <div className="col-auto">
          <div className={styles.day}>
            <p className={`${styles.lead} w-50`} />
            <p className={`${styles.desc} w-75`} />
          </div>
        </div>
        <div className="col row">
          { times(6, (idx) => this.renderMeasurementPlaceholder(idx)) }
        </div>
      </li>
    );
  }


  renderShowMoreLess() {
    const { printMode, isInProgress } = this.props;
    const { isShowAll, rowsIndex } = this.state;
    if (isInProgress || rowsIndex.length <= 1 || printMode) {
      return null;
    }
    return (
      <div className={cn('text--right', styles.buttonContainer__showLessMore)}>
        <Button
          styleModifier="primary"
          isDisabled={!isShowAll}
          className={cn('btn--sm', styles.button__showLess)}
          onClick={() => this.onToggleShowMore()}
        >
          <FormattedMessage {...messages.buttons.showLess} />
        </Button>
        <Button
          styleModifier="primary"
          isDisabled={isShowAll}
          className="btn--sm"
          onClick={() => this.onToggleShowMore()}
        >
          <FormattedMessage {...messages.buttons.showMore} />
        </Button>
      </div>
    );
  }


  renderRows() {
    if (this.props.isInProgress) {
      return this.renderRowPlaceholder();
    }
    const { rowsIndex } = this.state;
    if (!this.state.isShowAll) {
      return this.renderRow(first(rowsIndex));
    }
    return map(rowsIndex, (timestamp) => this.renderRow(timestamp));
  }


  renderShowRelatedData() {
    if (this.props.isReadOnly) {
      return null;
    }
    return (
      <CheckboxRadio
        inputValue="true"
        value={this.state.showRelatedData.toString()}
        labelMessage={messages.labels.showRelatedData}
        onChange={(input) => this.onChangeShowRelatedData(input)}
        className="text--large text--bold"
      />
    );
  }


  render() {
    return (
      <div className={cn(styles.root, { 'mt-4': this.props.isReadOnly })}>
        { this.renderShowRelatedData() }
        { this.renderRows() }
        { this.renderShowMoreLess() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  mode                  : selectors.mode(state),
  calculationFormula    : selectors.calculationFormula(state),
  aggregateBy           : selectors.aggregateBy(state),
  groupBy               : selectors.groupBy(state),
  activeReadingFromState: selectors.getMeasurement(state),
  printMode             : App.selectors.printMode(state),
  featureToggles        : App.selectors.featureToggles(state),
  openDropdownId        : App.selectors.dropdown(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenDropdown : (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
  onCloseDropdown: () => dispatch(App.actions.closeDropdown()),
});

const ConnectedReadingsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ReadingsTable));

export default withStyles(styles)(ConnectedReadingsTable);
