exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1vuv6{width:100%;padding:16px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._1vuv6>svg{width:100%}@media (min-width:1024px){._1vuv6>svg{width:auto}}", ""]);

// exports
exports.locals = {
	"AppLoader": "_1vuv6"
};