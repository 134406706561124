import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import map from 'lodash/map';
import includes from 'lodash/includes';
import ActiveNotingLabel from 'components/ActiveNotingLabel';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import BloodGlucoseConcentrationChart from 'components/Charts/BloodGlucoseConcentrationChart';
import App from 'modules/App';
import FormGroup from 'components/Form/FormGroup';
import Select from 'components/Form/Select';
import Button from 'components/Form/Button';
import Widget from 'components/Widget';
import intlShape from 'shapes/intlShape';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../Results/Results.pcss';


class TrendChartWidget extends React.PureComponent {

  static propTypes = {
    // Explicit props
    phiSet    : PropTypes.object,
    data      : PropTypes.array.isRequired,
    start     : PropTypes.number,
    end       : PropTypes.number,
    conversion: PropTypes.object.isRequired,
    direction : PropTypes.string.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }).isRequired,
    customRanges             : PropTypes.array,
    activePointTimestamp     : PropTypes.number,
    renderDatePresets        : PropTypes.func,
    renderFlagFilters        : PropTypes.func,
    className                : PropTypes.string,
    isReadOnly               : PropTypes.bool,
    isInProgress             : PropTypes.bool,
    highlightedReadings      : PropTypes.array,
    renderControls           : PropTypes.bool,
    relatedData              : PropTypes.array,
    timeSeriesResources      : PropTypes.array,
    measurements             : PropTypes.array,
    // Explicit actions
    onSetActivePointTimestamp: PropTypes.func.isRequired,
    onUpdatePhiSet           : PropTypes.func,
    onAddNote                : PropTypes.func,
    // Implicit props
    mode                     : PropTypes.oneOf(constants.MODES),
    deviceMode               : PropTypes.oneOf(constants.DEVICES_MODES),
    calculationFormula       : PropTypes.oneOf(
      [...constants.CALCULATION_FORMULAS, ...constants.CALCULATION_FORMULAS_CGM],
    ),
    aggregateBy            : PropTypes.oneOf(constants.AGGREGATE_BY),
    groupBy                : PropTypes.oneOf([...constants.GROUP_BY, ...constants.GROUP_BY_CGM]),
    isTrendChartLineEnabled: PropTypes.bool,
    intl                   : intlShape,
    printMode              : PropTypes.bool,
    featureToggles         : PropTypes.arrayOf(PropTypes.string),
    // Implicit actions
    onSetMode              : PropTypes.func,
    onSetDeviceMode        : PropTypes.func,
    onSetCalculationFormula: PropTypes.func,
    onSetAggregateBy       : PropTypes.func,
    onSetGroupBy           : PropTypes.func,
    onSetIsLineEnabled     : PropTypes.func,
  };


  onAddNote(timestamp, value, standardDeviation, unitSymbol, status) {
    const { mode, aggregateBy, calculationFormula } = this.props;
    let noteType = 'Reading';
    let start = timestamp;
    let end;
    let titleMessage = messages.labels.glucoseConcentration;
    if (mode === 'AGGREGATED') {
      noteType = upperFirst(camelCase(calculationFormula));
      titleMessage = messages.formulas[camelCase(calculationFormula)];
      const tsMoment = moment.unix(timestamp).utc().locale('en--account');

      if (calculationFormula === 'HYPERGLYCEMIA') status = 'high';
      else if (calculationFormula === 'HYPOGLYCEMIA') status = 'low';

      if (aggregateBy === 'DAY') {
        start = +tsMoment.clone().startOf('day').format('X');
      } else if (aggregateBy === 'WEEK') {
        start = +tsMoment.clone().startOf('week').format('X');
        end = +tsMoment.clone().endOf('week').format('X');
      } else if (aggregateBy === 'MONTH') {
        start = +tsMoment.clone().startOf('month').format('X');
        end = +tsMoment.clone().endOf('month').format('X');
      }
    }
    this.props.onAddNote(noteType, { value, standardDeviation, unitSymbol, status, start, end, titleMessage });
  }


  onBloodGlucoseConcentrationChartPointClick(point) {
    const activePointTimestamp = get(point, 'data.x', null);
    if (this.props.activePointTimestamp === activePointTimestamp) {
      return;
    }
    this.props.onSetActivePointTimestamp(activePointTimestamp);
  }


  onToggleJoinPoints() {
    this.props.onSetIsLineEnabled(!this.props.isTrendChartLineEnabled);
  }


  onUpdateCustomRange(idx, timestamp) {
    const { customRanges } = this.props;
    if (customRanges[idx]) {
      const momentDate = moment.unix(timestamp).utc().locale('en--account');
      customRanges[idx][0] = momentDate.hour();
      customRanges[idx][1] = momentDate.minutes();
      this.props.onUpdatePhiSet({ customRanges });
    }
  }


  onUpdateCustomRangeLabel(idx, label) {
    const { customRanges } = this.props;
    if (customRanges[idx]) {
      if (label) {
        customRanges[idx][2] = label;
      } else if (customRanges[idx][2]) {
        customRanges[idx].pop();
      }
      this.props.onUpdatePhiSet({ customRanges });
    }
  }


  onSplitCustomRange(idx, timestamp) {
    const customRanges = [...this.props.customRanges];
    if (customRanges[idx]) {
      const momentDate = moment.unix(timestamp).utc().locale('en--account');
      const customRange = [
        momentDate.hour(),
        momentDate.minutes(),
      ];
      customRanges.splice(idx + 1, 0, customRange);
      this.props.onUpdatePhiSet({ customRanges });
    }
  }


  onRemoveCustomRange(idx) {
    const customRanges = [...this.props.customRanges];
    const cdx = idx || 1;
    if (customRanges[cdx] && customRanges.length > 2) {
      const label = customRanges[cdx][2];
      customRanges.splice(cdx, 1);
      if (!idx) {
        customRanges[idx][2] = label;
      }
      this.props.onUpdatePhiSet({ customRanges });
    }
  }


  /*  get navTabsItems() {
    return [
      {
        label: messages.headers.measurementsTable,
        value: 'MeasurementsTable',
      },
      {
        label: messages.headers.myDownloads,
        value: 'ImportsHistory',
      },
    ];
  } */


  get customRangesLabels() {
    return map(this.props.customRanges, (bp) => bp[2]);
  }


  get ticks() {
    const { mode, aggregateBy, groupBy, start, end } = this.props;
    if (!start || !end) {
      return [];
    }

    if (mode === 'ALL') {
      return null;
    }

    if (mode === 'AGGREGATED') {
      if (aggregateBy === 'DAY') {
        return null;
      }

      const startDate = moment.unix(start).utc().locale('en--account');
      const endDate = moment.unix(end).utc().locale('en--account');
      const ticks = [];
      const unit = aggregateBy.toLowerCase();
      startDate.startOf(unit);
      endDate.endOf(unit);
      const difference = endDate.diff(startDate, unit, false);
      const interval = Math.max(Math.ceil(difference / 14), 1);
      ticks.push(+startDate.format('X'));
      while (startDate.isBefore(endDate)) {
        startDate.add(interval, unit);
        ticks.push(+startDate.format('X'));
      }
      return ticks;
    }

    if (mode === 'GROUPED') {
      const startDate = moment().utc().locale('en--account');
      const endDate = moment().utc().locale('en--account');
      const ticks = [];
      let unit = 'day';

      switch (groupBy) {
        case 'WEEKDAY': {
          startDate.startOf('week');
          endDate.endOf('week');
          break;
        }

        case 'MONTH':
        case 'QUARTER': {
          startDate.startOf('year');
          endDate.endOf('year');
          unit = groupBy.toLowerCase();
          break;
        }

        case 'HOURS_RANGE': {
          startDate.startOf('day');
          endDate.endOf('day');
          unit = 'hour';
          ticks.push(+startDate.format('X'));
          const breakpoints = [...this.props.customRanges];
          breakpoints.shift();
          forEach(breakpoints, (hourBreakpoint) => {
            ticks.push(+startDate.hour(hourBreakpoint[0]).minutes(hourBreakpoint[1]).format('X'));
          });
          ticks.push(+endDate.format('X') + 1);
          return ticks;
        }

        default: {
          break;
        }
      }

      ticks.push(+startDate.format('X'));
      while (startDate.isBefore(endDate)) {
        startDate.add(1, unit);
        ticks.push(+startDate.format('X'));
      }

      return ticks;
    }
    return null;
  }


  get tooltipConfig() {
    const { mode, aggregateBy, calculationFormula, intl } = this.props;

    if (calculationFormula !== 'NONE') {
      if (mode === 'AGGREGATED') {
        if (aggregateBy === 'DAY') {
          return { dateFormat: 'L' };
        }
        if (aggregateBy === 'WEEK') {
          return { dateFormat: intl.formatMessage(messages.momentFormats.week, { format: 'wo' }) };
        }
        if (aggregateBy === 'MONTH') {
          return { dateFormat: 'MMMM' };
        }
      }

      if (mode === 'GROUPED') {
        return {};
      }
    }

    return {
      dateFormat: 'L',
      timeFormat: 'LT',
    };
  }


  get maxPlainValue() {
    const { calculationFormula } = this.props;
    if (includes(['HYPERGLYCEMIA', 'HYPOGLYCEMIA'], calculationFormula)) {
      return 100;
    }
    return 20;
  }


  get plainValuesColor() {
    const { calculationFormula } = this.props;
    if (calculationFormula === 'HYPERGLYCEMIA') {
      return '#F4C32C';
    }
    if (calculationFormula === 'HYPOGLYCEMIA') {
      return '#F74053';
    }
    return null;
  }


  get unitSymbol() {
    const { calculationFormula } = this.props;
    if (includes(['HYPERGLYCEMIA', 'HYPOGLYCEMIA'], calculationFormula)) {
      return '%';
    }
    return null;
  }


  get isCustomRanges() {
    const { mode, groupBy } = this.props;
    return mode === 'GROUPED' && groupBy === 'HOURS_RANGE';
  }


  formatLabel(value, firstTick, lastTick, xGap) {
    const { mode, aggregateBy, groupBy, direction, intl } = this.props;

    const momentDate = moment.unix(value).utc();

    if (mode === 'ALL') {
      return momentDate.format('L');
    }

    if (mode === 'AGGREGATED') {
      if (aggregateBy === 'DAY') {
        return momentDate.format('L');
      }
      if (aggregateBy === 'WEEK') {
        momentDate.endOf('week');
        return intl.formatMessage(messages.chartLabels.week, {
          week: momentDate.format('wo'),
          year: momentDate.format('YYYY'),
        });
      }
      if (aggregateBy === 'MONTH') {
        return `${momentDate.format('MMMM')} ${momentDate.format('YYYY')}`;
      }
    }

    const x = `${direction === 'rtl' ? '-' : ''}${xGap / 2}`;

    if (mode === 'GROUPED') {
      if (value === lastTick) {
        return null;
      }
      if (groupBy === 'WEEKDAY') {
        return <tspan x={x}>{ momentDate.format('dddd') }</tspan>;
      }
      if (groupBy === 'MONTH') {
        return <tspan x={x}>{ momentDate.format('MMMM') }</tspan>;
      }
      if (groupBy === 'QUARTER') {
        return (
          <tspan x={x}>
            {
              intl.formatMessage(messages.chartLabels.quarter, {
                quarter: momentDate.format('Qo'),
              })
            }
          </tspan>
        );
      }
      if (groupBy === 'HOURS_RANGE') {
        return null;
      }
    }

    return momentDate.format('L');
  }


  renderJoinOptions() {
    const { mode, isTrendChartLineEnabled, isInProgress, printMode } = this.props;
    if (mode === 'GROUPED' || printMode) {
      return null;
    }

    return (
      <div className="btn-group col-auto">
        <CheckboxRadio
          id="joinPoints"
          inputValue="true"
          value={isTrendChartLineEnabled ? isTrendChartLineEnabled.toString() : 'false'}
          labelMessage={messages.buttons.joinPoints}
          className={styles.joinPointsCheckbox}
          onChange={
            () => {
              this.onToggleJoinPoints();
            }
          }
          isDisabled={isInProgress}
        />
      </div>
    );

  }


  renderPresentationTypes() {
    const { isInProgress } = this.props;
    return (
      <div>
        <p className="form-label"><FormattedMessage {...messages.labels.presentationTypes} /></p>
        <div className="row">
          <div className="btn-group col-auto">
            {
              map(constants.MODES, (mode) => (
                <Button
                  key={mode}
                  styleModifier="quaternary"
                  className={cn('btn--filled', { 'text--primary': mode === this.props.mode })}
                  labelMessage={messages.buttons[mode.toLowerCase()]}
                  isDisabled={isInProgress}
                  onClick={() => this.props.onSetMode(mode)}
                />
              ))
            }
          </div>
          {
            this.props.featureToggles.includes(App.constants.FEATURE_TOGGLES.featureCgm)
              && (
                <div className="btn-group col-auto">
                  {
                    map(constants.DEVICES_MODES, (mode) => (
                      <Button
                        key={mode}
                        styleModifier="quaternary"
                        className={cn('btn--filled', { 'text--primary': mode === this.props.deviceMode })}
                        labelMessage={messages.buttons[mode.toLowerCase()]}
                        isDisabled={isInProgress}
                        onClick={() => this.props.onSetDeviceMode(mode)}
                      />
                    ))
                  }
                </div>
              )
          }
        </div>
      </div>
    );
  }


  renderCalculationFormulas() {
    const { mode, calculationFormula, isInProgress, intl } = this.props;
    const calculationFormulas = [...constants.CALCULATION_FORMULAS];
    if (mode === 'AGGREGATED') {
      calculationFormulas.shift();
    }
    return (
      <FormGroup
        id="calculationFormula"
        labelMessage={messages.labels.calculationFormula}
        className={styles.sortOptions__option}
        formValues={{ values: { calculationFormula } }}
      >
        <Select
          optionsFrom={
            map(calculationFormulas, (formula) => ({
              value: formula,
              label: intl.formatMessage(messages.formulas[camelCase(formula)]),
            }))
          }
          valueKey="value"
          labelKey="label"
          isDisabled={isInProgress}
          onChange={(input) => this.props.onSetCalculationFormula(input.value)}
        />
      </FormGroup>
    );
  }


  renderAggregateBy() {
    const { mode, aggregateBy, isInProgress, intl } = this.props;
    if (mode !== 'AGGREGATED') {
      return null;
    }

    return (
      <FormGroup
        id="aggregateBy"
        labelMessage={messages.labels.aggregateBy}
        className={styles.sortOptions__option}
        formValues={{ values: { aggregateBy } }}
      >
        <Select
          optionsFrom={
            map(constants.AGGREGATE_BY, (unit) => ({
              value: unit,
              label: intl.formatMessage(messages.aggregateBy[unit.toLowerCase()]),
            }))
          }
          valueKey="value"
          labelKey="label"
          isDisabled={isInProgress}
          onChange={(input) => this.props.onSetAggregateBy(input.value)}
        />
      </FormGroup>
    );
  }


  renderGroupBy() {
    const { mode, groupBy, isInProgress, intl } = this.props;
    if (mode !== 'GROUPED') {
      return null;
    }
    return (
      <FormGroup
        id="groupBy"
        labelMessage={messages.labels.groupBy}
        className={styles.sortOptions__option}
        formValues={{ values: { groupBy } }}
      >
        <Select
          optionsFrom={
            map(constants.GROUP_BY, (type) => ({
              value: type,
              label: intl.formatMessage(messages.groupBy[type.toLowerCase()]),
            }))
          }
          valueKey="value"
          labelKey="label"
          isDisabled={isInProgress}
          onChange={(input) => this.props.onSetGroupBy(input.value)}
        />
      </FormGroup>
    );
  }


  renderPresentationOptions() {
    if (this.props.mode === 'ALL') {
      return null;
    }

    return (
      <div className="d-flex justify-content-end">
        { this.renderCalculationFormulas() }
        { this.renderAggregateBy() }
        { this.renderGroupBy() }
      </div>
    );
  }


  renderFilters() {
    return (
      <div className={cn(styles.chartControls__filters, styles.chartControls__wrapper, 'row')}>
        <div className="col-auto mb-4">
          <p className="form-label"><FormattedMessage {...messages.labels.periodWithResults} /></p>
          { this.props.renderDatePresets() }
        </div>
        <div className="col-auto mb-4">
          <p className="form-label"><FormattedMessage {...messages.labels.flagsFilters} /></p>
          { this.props.renderFlagFilters() }
        </div>
      </div>
    );
  }


  renderChartControls() {
    const { printMode, renderControls } = this.props;

    if (printMode || !renderControls) {
      return null;
    }

    return (
      <div className={cn(styles.chartControls, { fadingLoader: this.props.isInProgress })}>
        <div className="row justify-content-between">
          <div className="col-auto mb-4">
            { this.renderPresentationTypes() }
          </div>
          <div className="col-auto mb-4">
            { this.renderPresentationOptions() }
          </div>
        </div>
        { this.renderFilters() }
      </div>
    );
  }


  renderUnit(conversion) {
    const { calculationFormula } = this.props;
    let unit = conversion.unitSymbol;
    switch (calculationFormula) {
      case 'HYPOGLYCEMIA':
      case 'HYPERGLYCEMIA':
        unit = App.constants.UNITS_SYMBOLS.PERCENT;
        break;
      case 'COUNT':
        unit = <FormattedMessage {...messages.labels.unitCount} />;
        break;
      default:
        break;
    }
    return (
      <p className={styles.bloodGlucoseConcentrationUnit}>
        <FormattedMessage
          {...messages.labels.unit}
          values={{ unit }}
        />
      </p>
    );
  }


  renderActiveNotingLabel() {
    if (!this.props.onAddNote) {
      return null;
    }
    return <ActiveNotingLabel labelMessage={messages.noting.clickPoint} />;
  }


  render() {
    const {
      data, mode, calculationFormula, start, end, conversion, direction,
      standards, className, isReadOnly, printMode, isTrendChartLineEnabled,
      relatedData, timeSeriesResources, aggregateBy, groupBy, intl,
    } = this.props;

    let headerAdditionalMessage = '';

    switch (mode) {
      case 'GROUPED':
        headerAdditionalMessage = ` ${intl.formatMessage(messages.formulas[camelCase(calculationFormula)])},
        ${intl.formatMessage(messages.groupBy[groupBy.toLowerCase()])}`;
        break;
      case 'AGGREGATED':
        headerAdditionalMessage = ` ${intl.formatMessage(messages.formulas[camelCase(calculationFormula)])},
          ${intl.formatMessage(messages.aggregateBy[aggregateBy.toLowerCase()])}`;
        break;

      default:
        break;
    }
    return (
      <Widget
        widgetId={constants.TREND_CHART_WIDGET}
        headerMessage={messages.headers.bloodGlucoseConcentration}
        headerAdditionalMessage={headerAdditionalMessage}
        className={className}
        headerAdditionalMessageClassName="widget__header__additional__bold"
        style={{ height: printMode ? '343px' : 'auto' }}
      >
        { this.renderActiveNotingLabel() }
        { this.renderChartControls() }
        <div className="d-flex justify-content-between align-items-center">
          { this.renderUnit(conversion) }
          { this.renderJoinOptions() }
        </div>
        <BloodGlucoseConcentrationChart
          conversion={conversion}
          standards={standards}
          readings={data}
          relatedData={relatedData}
          timeSeriesResources={timeSeriesResources}
          start={start}
          end={end}
          printMode={printMode}
          timestampKey="timestamp"
          valueKey="value"
          direction={direction}
          ticks={this.ticks}
          customRangesLabels={this.customRangesLabels}
          maxPlainValue={this.maxPlainValue}
          unitSymbol={this.unitSymbol}
          isLineEnabled={mode !== 'GROUPED' && isTrendChartLineEnabled}
          isGridXEnabled={mode === 'GROUPED' && (!this.isCustomRanges || isReadOnly)}
          isPlainValuesMode={includes(['COUNT', 'HYPERGLYCEMIA', 'HYPOGLYCEMIA'], calculationFormula)}
          plainValuesColor={this.plainValuesColor}
          isCustomRanges={this.isCustomRanges}
          isReadOnly={isReadOnly}
          isInProgress={this.props.isInProgress}
          formatLabel={(value, firstTick, lastTick, xGap) => this.formatLabel(value, firstTick, lastTick, xGap)}
          tooltipConfig={this.tooltipConfig}
          onAddNote={this.props.onAddNote ? (...props) => this.onAddNote(...props) : null}
          onPointClick={(point) => this.onBloodGlucoseConcentrationChartPointClick(point)}
          onUpdateCustomRange={(idx, timestamp) => this.onUpdateCustomRange(idx, timestamp)}
          onUpdateCustomRangeLabel={(idx, label) => this.onUpdateCustomRangeLabel(idx, label)}
          onSplitCustomRange={(idx, timestamp) => this.onSplitCustomRange(idx, timestamp)}
          onRemoveCustomRange={(idx) => this.onRemoveCustomRange(idx)}
          highlightedReadings={this.props.highlightedReadings}
          measurements={this.props.measurements}
          showRelatedData={mode !== 'GROUPED'}
        />
      </Widget>
    );
  }

}


const mapStateToProps = (state) => ({
  mode                   : selectors.mode(state),
  deviceMode             : selectors.deviceMode(state),
  calculationFormula     : selectors.calculationFormula(state),
  aggregateBy            : selectors.aggregateBy(state),
  groupBy                : selectors.groupBy(state),
  isTrendChartLineEnabled: selectors.isTrendChartLineEnabled(state),
  printMode              : App.selectors.printMode(state),
  featureToggles         : App.selectors.featureToggles(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSetMode              : (mode) => dispatch(actions.setMode(mode)),
  onSetDeviceMode        : (deviceMode) => dispatch(actions.setDeviceMode(deviceMode)),
  onSetCalculationFormula: (calculationFormula) => dispatch(actions.setCalculationFormula(calculationFormula)),
  onSetAggregateBy       : (aggregateBy) => dispatch(actions.setAggregateBy(aggregateBy)),
  onSetGroupBy           : (groupBy) => dispatch(actions.setGroupBy(groupBy)),
  onSetIsLineEnabled     : (isEnabled) => dispatch(actions.setIsTrendChartLineEnabled(isEnabled)),
});


const ConnectedTrendChartWidget = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(TrendChartWidget));


export default ConnectedTrendChartWidget;
